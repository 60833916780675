<template>
  <div>
       <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
      </el-row>
    </div>
        <el-form
        ref="perForm"
        :model="formdata"
        :rules="rules"
        label-width="200px"
      >

      
<el-form-item label="来源类型" prop="moduleName" v-if="!formdata.msgId">
       <el-radio v-model="formdata.moduleName" label='ABOUTUS' @change="change">关于我们</el-radio>
       <el-radio v-model="formdata.moduleName" label="VIPSERVICE" @change="change">会员服务信息</el-radio>
    
      </el-form-item>
              
      
       <el-form-item label="分类类型" prop="typeId"  v-if="formdata.moduleName=='ABOUTUS'|formdata.typeId">
          <el-select
            v-model="formdata.typeId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      <!-- <el-form-item label="文本内容" prop="content">
          <el-input
            v-model="formdata.content"
            placeholder="请输入文本内容"
            style="width: 50%"
          />
        </el-form-item> -->

      <el-form-item label="文本内容" prop="content" >
          <div style="line-height: normal">
            <uEditor v-model="formdata.content"></uEditor>
          </div>
        </el-form-item>
      
     
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {
     addCommonMsg,
 modifyCommonMsg,
  addType,
  getUploadToken,
  getTypes,
  getArticles,
  addArticle,
  setArticleStatus,
  modifyArticle,
  delArticle,
} from "../../request/http";
export default {
  data() {
    return {
   
      editOradd: "",
   
       options: [],
     formdata: {
        moduleName:'',
        typeId:null,
        content:'',
      },
   rules: {
     
        
        moduleName: [
          { required: true, message: "模块信息不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "文本内容不能为空", trigger: "blur" },
        ],
        
          typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
    
      },
      

     
    };
  },
  created() {
        this.getList()

    console.log(this.$route.query);
    //editOradd 传过来的为 0 或者 1
    this.editOradd = this.$route.query.editOradd == 0 ? false : true;
    if (this.$route.query.obj) {
      let val = this.$route.query.obj;
       this.formdata = {
        typeId:val.typeId,
        msgId: val.msgId,
        content:val.content,
     
      };
    }
  },
  mounted() {},

  methods: {
async getList() {
      let res = await getTypes({
        typeGroup: "ABOUTUS",
      });
      console.log(res);

      this.options = res.data;
    },
   selectChange(value) {
      this.formdata.typeId = value;
      console.log(this.formdata.typeId);
    },
      async change(val){
      console.log(val);
      this.formdata.moduleName = val
      },
    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 确认
      async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.formdata.msgId) {
 
          let { code, msg } = await modifyCommonMsg(this.formdata);
          if (this.formdata.msgId && code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        } else {
        
          let { code, msg } = await addCommonMsg(this.formdata);
          console.log(code, msg);
          if (code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        }
      });
   
    },

    //弹窗取消函数
    btnCancel() {
      this.goBack();
    },
  

   
  
  },
};
</script>

<style>

</style>